<template lang="pug">
v-card(v-if="Object.keys(sailorDocument).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div {{ $t('user').toUpperCase() }} (ID: {{ sailorDocument.id }})
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-card-text
    v-divider
    AccountManagementInfo(v-if="sailorDocument.behavior.viewInfoBlock" :sailorDocument="sailorDocument" :permissions="permissions")
    AccountManagementEdit(v-else-if="sailorDocument.behavior.viewEditBlock" :sailorDocument="sailorDocument" @loadData="loadData")
    AccountSettings(v-else-if="sailorDocument.behavior.viewSettingsBlock" :accountData="sailorDocument" @loadData="loadData")
    ViewPhotoList(
      v-else-if="sailorDocument.behavior.viewFilesBlock"
      :sailorDocument="sailorDocument"
      :allowDelete="false"
      documentType="accountManagement")

  ChangePasswordModal(ref="changePasswordModal")
</template>

<script>
import { back, viewDetailedComponent } from '@/mixins/main'
import { mapActions } from 'vuex'
import { checkAccess } from '@/mixins/permissions.js'

export default {
  name: 'AccountManagementDocument',
  components: {
    AccountManagementInfo: () => import('@/views/AccountManagement/AccountManagementInfo.vue'),
    AccountManagementEdit: () => import('@/views/AccountManagement/AccountManagementEdit.vue'),
    ChangePasswordModal: () => import('@/views/AccountManagement/ChangePasswordModal.vue'),
    AccountSettings: () => import('@/views/AccountManagement/AccountSettings.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewPhotoList.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons.vue')
  },
  data () {
    return {
      controlButtons: [
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewInfoBlock'),
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditBlock'),
          checkAccess: () => checkAccess('accountManagement', 'edit'),
          color: '#42627e',
          tooltip: 'documentIconControl.edit',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 3,
          name: 'mdi-key',
          action: () => this.changeModalState(),
          checkAccess: () => checkAccess('accountManagement', 'changePassword'),
          color: '#42627e',
          tooltip: 'changePassword',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 4,
          name: 'mdi-lock-outline',
          action: () => this.changeActivityStatus(),
          checkAccess: () => checkAccess('accountManagement', 'edit') && this.sailorDocument.is_active,
          color: 'error',
          tooltip: 'block',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: 'error', outlined: false }
        },
        {
          id: 5,
          name: 'mdi-lock-open-variant-outline',
          action: () => this.changeActivityStatus(),
          checkAccess: () => checkAccess('accountManagement', 'edit') && !this.sailorDocument.is_active,
          color: 'success',
          tooltip: 'unblock',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: 'success', outlined: false }
        },
        {
          id: 6,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewFilesBlock'),
          color: '#42627e',
          tooltip: 'documentIconControl.files',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 7,
          name: 'mdi-account-cog-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewSettingsBlock'),
          checkAccess: () => checkAccess('backOffice'),
          color: 'black',
          tooltip: 'documentIconControl.accountSettings',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: 'black', outlined: false }
        },
        {
          id: 8,
          name: 'mdi-close',
          action: () => this.$router.go(-1),
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ],
      sailorDocument: {},
      permissions: [],
      isActivityStatusChanging: false,
      viewDetailedComponent,
      checkAccess,
      back
    }
  },
  computed: {
    documentID () {
      return this.$route.params.documentID
    }
  },
  mounted () {
    this.loadData()
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'accountManagement', access: checkAccess('accountManagement', 'view') })
  },
  methods: {
    ...mapActions(['getUser', 'getSpecificUserPermissions', 'updateUser', 'createForUserFido2']),

    changeModalState () { // Open/Close change password modal
      this.$refs.changePasswordModal.changeModalState()
    },

    loadData () {
      this.getUser(this.documentID).then(response => { // Get user profile information
        if (response.code === 200) {
          response.data.behavior = { viewInfoBlock: true }
          this.sailorDocument = response.data
        }
      })
      this.getSpecificUserPermissions(this.documentID).then(response => { // Get specific user permissions
        if (response.code === 200) this.permissions = response.data
      })
    },

    async changeActivityStatus () {
      const data = { ...this.$route.params, body: { is_active: !this.sailorDocument.is_active } }
      this.isActivityStatusChanging = true
      const response = await this.updateUser(data)
      if (response) {
        this.sailorDocument.is_active = !this.sailorDocument.is_active
        if (this.sailorDocument.is_active) this.$notification.success('successUnblockedUser')
        else this.$notification.success('successBlockedUser')
      } else this.$notification.error('notify.responseField')
      this.isActivityStatusChanging = false
    }
  }
}
</script>

<style lang="sass">
.permissions
  width: 100%
  height: 600px
  margin: 15px
  border: 2px solid #e5e5e5
  border-radius: 4px
  position: relative
  overflow: auto
  &-search
    position: absolute
    top: 0
    width: 100%
    height: 50px
    overflow: hidden
  &-list
    position: absolute
    top: 50px
    width: 100%
    height: 546px
    overflow: auto
    overflow-x: hidden
    padding: 0 12px
</style>
